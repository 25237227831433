import { Loading } from 'components';
import { navigate } from 'gatsby';
import { ApplicationState } from 'modules/redux-store';
import React, { lazy } from 'react';
import { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';

const AdminInbox = lazy(() => import('modules/admin/components/AdminInbox'));

const Admin: React.FC = () => {
  const { user } = useSelector((state: ApplicationState) => state.auth);

  useEffect(() => {
    if (!user) navigate('/prijava');
    else if (user.role !== 'admin') navigate('/');
  }, [user]);

  if (user?.role === 'admin') {
    return (
      <Suspense fallback={<Loading isLoading />}>
        <AdminInbox />
      </Suspense>
    );
  } else {
    return <Loading isLoading fullPage />;
  }
};

export default Admin;
